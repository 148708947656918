@import '../../index.css';

.auth-body {
    /* background: rgba(175,222,246, 1);
  background: linear-gradient(90deg, rgba(175,222,246,1) 0%, rgba(247,175,255,1) 100%); */
    /* background: rgb(255,252,9);
background: linear-gradient(90deg, rgba(255,252,9,0.7) 0%, rgba(59,232,0,0.7) 100%); */
    background: rgb(230, 240, 255);
    background: linear-gradient(45deg, rgba(0, 223, 154, 0.5) 0%, rgba(0, 223, 154, 1) 100%);
    background-image: url(../../assets/wallpaper.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.auth {
    min-width: 25% !important;
    margin: 0 10px;
    padding: 4rem 3rem 6rem;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    transition: .5s;
    color: black;


}

.auth:hover {
    box-shadow: none;
}


.auth Button {
    width: 100%;
    display: block;
    margin: 1.5rem auto;
    background-color: #059669 !important;
}

.register-body {
    /* background: rgba(175,222,246, 1);
    background: linear-gradient(90deg, rgba(175,222,246,1) 0%, rgba(247,175,255,1) 100%); */
    /* background: rgb(255,252,9);
    background: linear-gradient(90deg, rgba(255,252,9,0.7) 0%, rgba(59,232,0,0.7) 100%); */
    background: rgb(230, 240, 255);
    background: linear-gradient(45deg, rgba(0, 223, 154, 0.5) 0%, rgba(0, 223, 154, 1) 100%);
    background-image: url(../../assets/wallpaper.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.register {
    min-width: 25% !important;
    margin: 35px;
    padding: 1.5rem 3rem 1rem;
    border-radius: 20px !important;
    box-shadow: var(--box-shadow);
    transition: .5s;
    color: black;


}

.register:hover {
    box-shadow: none;
}


.register Button {
    width: 100%;
    display: block;
    margin: 1.5rem auto;
    background-color: #059669 !important;
}

.navigationlink {
    color:#10b981 !important;
}